import React, { Component } from 'react'
import Header from './header';
import Footer from './footer';
import axios from 'axios';
import moment from 'moment';
import Loader from '../images/loader2.gif';

export class weeklyreport extends Component {
  constructor() {
    super();
    
    this.state = {
      LoginDatas:  JSON.parse(localStorage.getItem("HelpDeskLoginDatas")),
      TodayDate: new Date(),
      project_data : [],
      InProgressData: [],
      AssignedData: [],
      weeklyreportData: [],
      weekDates: this.getWeekDays(new Date()),
      DepartmentData : [],
      SelectedDepartment: '0',
      ProjectName: 'All',
      ProjectId: '0',
      Auth:  JSON.parse(localStorage.getItem("auth")),
      LoaderShow: false,
      selectedDatefunc: new Date(),
      selectedDate: moment(new Date()).format("MM/DD/YYYY"),
      selectedDate2: moment(new Date()).format("MM/DD/YYYY"),
      
    };
  }

componentDidMount(){

        axios.post('https://helpdeskservice.desss-portfolio.com/api/LodeProjectBasedEMP/Project?UserType_Id='+this.state.LoginDatas.UserTypeID+'&Emp_id=' + this.state.LoginDatas.EmpID + '&Dept_id=' + this.state.LoginDatas.DeptID)
        .then( project => {
          var data = project.data.data;
          console.log("project" , project.data.data)
          data.map((key) =>{
            this.setState({ project_data: [ ...this.state.project_data, {
              ProjectName: key.ProjectName ,
              ProjectID: key.ProjectID
           }]
         });
         });
        })
        .catch(error => {
            console.log(error);
        });
        
        axios.get('https://helpdeskservice.desss-portfolio.com/api/DeptDropdown/LoadDeptDropdown')
        .then( res => {
          var data1 = res.data.data;
          console.log("deparmen" , res.data.data)
          data1.map((key) =>{
            this.setState({ DepartmentData: [ ...this.state.DepartmentData, {
              Department: key.Department ,
              DeptID: key.DeptID
           }]
         });
         });
         console.log("DepartmentData" , this.state.DepartmentData)
        })
        .catch(error => {
            console.log(error);
        })    

        // console.log('weeklyreport',  this.getWeekDays(this.state.selectedDatefunc));
        // this.setState({ weekDates:  this.getWeekDays(this.state.selectedDatefunc) });
        
        //  this.GetDatas();
}

GetDatas(){
  console.log('GetDatas',  this.state.SelectedDepartment);
  console.log('GetDatas',  this.state.weekDates);
  var weeklyreport = [];


  this.setState({ weeklyreportData: weeklyreport });
 
  var date =  moment(new Date()).format("MM/DD/YYYY");
    this.setState({ LoaderShow: true }); 
    
    axios.get('https://helpdeskservice.desss-portfolio.com/api/WeeklyTaskList/GetWeeklyTaskListNew?MondayDate='+this.state.weekDates[0]+'&TuesdayDate='+this.state.weekDates[1]+'&WednesdayDate='+this.state.weekDates[2]+'&ThursdayDate='+this.state.weekDates[3]+'&FridayDate='+this.state.weekDates[4]+'&SaturdayDate='+this.state.weekDates[5]+'&Dept='+this.state.SelectedDepartment)
    // axios.get('https://helpdeskservice.desss-portfolio.com/api/WeeklyTaskList/GetWeeklyTaskList?MondayDate='+this.state.weekDates[0]+'&TuesdayDate='+this.state.weekDates[1]+'&WednesdayDate='+this.state.weekDates[2]+'&ThursdayDate='+this.state.weekDates[3]+'&FridayDate='+this.state.weekDates[4]+'&SaturdayDate='+this.state.weekDates[5]+'&Dept='+this.state.SelectedDepartment)
    // axios.get('https://helpdeskservice.desss-portfolio.com/api/WeeklyTaskList/GetWeeklyTaskList?MondayDate='+this.state.weekDates[0]+'&TuesdayDate='+this.state.weekDates[1]+'&WednesdayDate='+this.state.weekDates[2]+'&ThursdayDate='+this.state.weekDates[3]+'&FridayDate='+this.state.weekDates[4]+'&SaturdayDate='+this.state.weekDates[5]+'&Dept=16')
    .then(res => {
      var data = res.data.data;
      this.setState({ LoaderShow: false });  
      if(data[0].errorMsg === "No Data Found"){
        return;
      }
      // data.forEach((key, value) => {   
      //   weeklyreport.push({
      //     EmpID: key.EmpID, 
      //     DeptID: key.DeptID,
      //     Department: key.Department,
      //     TaskRowCount: key.TaskRowCount,
      //     TimesheetRowCount: key.TimesheetRowCount,
      //     FullName: key.FullName,
      //     PrjTaskAllotID: key.PrjTaskAllotID,
      //     TimeSheetID: key.TimeSheetID,
      //     ProjectName: key.ProjectName,
      //     ModuleName: key.ModuleName,
      //     TaskName: key.TaskName,
      //     TaskStatus: key.TaskStatus,
      //     AllotedHrs: key.AllotedHrs,
      //     TimesheetAllotedHrs: key.TimesheetAllotedHrs,
      //     ActualWorkedHours: key.ActualWorkedHours,
      //     ProjectID: key.ProjectID,
      //     AllotedDate: moment(key.AllotedDate).format("MM/DD/YYYY"),
      //     DayCount: key.DayCount,
      //     DeptCount: key.DeptCount,
      //     SprintName: key.SprintName,
      //     SprintDeliveryDate: key.SprintDeliveryDate,
          
      //   });
          
      // });

      this.setState({ weeklyreportData: data });
      console.log('weeklyreportData', this.state.weeklyreportData);
      // console.log('Assigned', this.state.Assigned);
    }) 
    .catch(error => {
      this.setState({ LoaderShow: false });  
        console.log(error);
    })

}

getWeekDays(date){
  const weekDays = [];
  const currentDate = date;
  const currentDay = currentDate.getDay();
  const firstDayOfWeek = currentDate.getDate() - currentDay + (currentDay === 0 ? -6 : 1); // Adjust if Sunday (0)

  // Loop through Monday (1) to Saturday (6) and add them to the array
  for (let i = 1; i <= 6; i++) {
    const targetDate = new Date(currentDate.setDate(firstDayOfWeek + i - 1));
    weekDays.push(this.formatDate(targetDate));
  }

  return weekDays;
};


formatDate = (date) => {
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based, so add 1
  const day = String(date.getDate()).padStart(2, '0');
  const year = date.getFullYear();
  return `${month}/${day}/${year}`;
};

DepartmentChange = (e) => {
  this.setState({ SelectedDepartment: e.target.value });
}

DateChange = (e) => {
  console.log('Date Change2', new Date(e.target.value));
  this.setState({ selectedDatefunc: new Date(e.target.value) });
  this.setState({ selectedDate: moment(e.target.value).format("MM/DD/YYYY") });
  console.log('Date Change2', this.state.selectedDatefunc);
  this.setState({ weekDates:  this.getWeekDays(new Date(e.target.value)) });
  console.log('Date Change date2', this.state.weekDates);
}

render() {
    return (
      <div>

         {this.state.LoaderShow === true ? (
          <div className='loader-img'>
            <img   src={Loader} height="50" width="50" alt="Helpdesk" />
          </div>
        ) : (
          ''
        )}

        <Header />

          <div>
  
<div class="centerBG" style={{border: '1px solid #898989'}}>


 <div
  style={{
    paddingBottom: '10px',
    paddingTop: '20px'
  }}
>
  <table>
    <tbody>
      <tr>
        <td>
          <div style={{paddingTop: '20px',marginLeft:'40px'}}>
            <span id="label_Text" style={{color: '#4c4a48', fontSize: '25px', fontWeight: 'bold'}}>Task Allotment Report</span>
          </div>
        </td>
        <td>
          <div style={{display: "flex",alignItems : "center",justifyContent:"center"}}>
            <span id="Label74" style={{fontSize:"17px",color:"#4c4a48",paddingRight:"10px"}}>Department : </span>
              <select name="system_ton" id="ddlTaskStatus" class="select_new MB10 fleft selector_s" onChange={this.DepartmentChange}  value={this.state.SelectedDepartment} style={{marginleft:"10px", width:"210px", padding: "10px"}}>
                                                              <option value="0">All</option>
                                                                { this.state.DepartmentData.map((element) => 
                                                                  <option value={element.DeptID}>{element.Department}</option>
                                                                )}
                                                            </select>
              <div style={{display: "flex"}}>
              <p style={{fontSize: "17px",margin: "0px",color:"#4c4a48"}} >Date :</p>
              <input id="dateRequired" type="text" value={this.state.selectedDate} readOnly style={{borderRight:"none"}} />
              <input id="dateRequired1" type="date" name="editmoduleFinaldate1" onChange={this.DateChange} style={{width: '38px',borderLeft:"none"}}/>
              <button class="tab-label"  style={{backgroundColor: "rgb(76, 74, 72)", color: "rgb(255, 255, 255)", border: "none", padding: "7px 15px", marginTop: "10px", borderRadius: "6px", marginRight: "10px",marginLeft:"20px"}} onClick={() =>this.GetDatas()}>View</button>
            </div>
                                                            
        </div>
        </td>
      </tr>
    </tbody>
  </table>
</div>

<div id="sortable-div1" class="drag_drop_main_containers">
        <div width="100%" class="drag_drop_table">
            <div class="thead" id="datedisplay">
              <div class="theadrow">
                <h3>{this.state.weekDates[0]}</h3>
                <h3>{this.state.weekDates[1]}</h3>
                <h3>{this.state.weekDates[2]}</h3>
                <h3>{this.state.weekDates[3]}</h3>
                <h3>{this.state.weekDates[4]}</h3>
                <h3>{this.state.weekDates[5]}</h3>              
              </div>
            </div>
        </div>
    </div>

{
 this.state.weeklyreportData.map((item) => (
  <div className="dept-5">
   <div className="hide-class deptid-5">
    <table>
      <tbody>
        <tr>
          <td>
            <h3 className="employe_name">
              Department :{item.Department}
            </h3>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  {
 item.Employees.map((item1) => (
<div>
<div
    className="hide-class deptemp-5 empname-31"
    style={{
      paddingLeft: '30px'
    }}
  >

    <table>
      <tbody>
        <tr>
          <td>
            <h3 className="employe_name">
              Employee : {item1.FullName}
            </h3>
          </td>
        </tr>
      </tbody>
    </table>

</div>



<div className="drag_drop_main_container depttask-5  emptask-31">
    <div className="drag_drop_table">
      <div
        className="tbody"
        id="weeklytasklist-31"
      >
        <div
          className="trow"
          id="TasklistMuthu"
        >
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Monday31"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Tuesday31"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a className="Addtask"href=" " >
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Wednesday31"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Thursday31"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Friday31"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Saturday31"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</div>

</div>

))
}

  {/* <div
    className="hide-class deptemp-5 empname-104"
    style={{
      paddingLeft: '30px'
    }}
  >
    <table>
      <tbody>
        <tr>
          <td>
            <h3 className="employe_name">
              Employee : Aravindasamy R
            </h3>
          </td>
        </tr>
      </tbody>
    </table>

  </div>
  <div className="drag_drop_main_container depttask-5  emptask-104">
    <div className="drag_drop_table">
      <div
        className="tbody"
        id="weeklytasklist-104"
      >
        <div
          className="trow"
          id="TasklistAravindasamy"
        >
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Monday104"
                >
                  <li className="sortable-item">
                  <div
  className="drop_top progress_border"
  id="52632"
>
  <h4
    style={{
      fontSize: '15px',
      fontWeight: '200'
    }}
  >
    jet rubber website
  </h4>
  <h4
    style={{
      fontSize: '15px',
      fontWeight: '200'
    }}
  >
    components
  </h4>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    Task :
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
      admin mobile view
    </span>
  </p>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    {' '}Allot Hrs :
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
      9
    </span>
  </p>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    {' '}Status:{' '}
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
      Completed
    </span>
  </p>
  <p
    style={{
      color: '#cc0000',
      fontSize: '15px',
      fontWeight: '150'
    }}
  >
    TimeSheet :
    <span
      style={{
        color: 'black',
        fontSize: '15px',
        fontWeight: '100'
      }}
    >
      {' '}Not Updated{' '}
    </span>
  </p>
  <a className="Addtask" href=" ">Add</a>
  <a className="Deletetask" href=" " target="_blank">Del</a>
  <a className="Edit" href=" ">Edit</a>
  <a className="Duplicate" href=" ">Dup</a>
</div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Tuesday104"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Wednesday104"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Thursday104"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Friday104"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="tdiv">
            <div className="drag_drop_container1">
              <div className="drag_contant">
                <ul
                  className="sortable-list drag_drop_list"
                  id="Saturday104"
                >
                  <li className="sortable-item">
                    <div className="drop_top progress_border">
                      <p
                        style={{
                          color: 'Red',
                          fontSize: '15px',
                          fontWeight: '150'
                        }}
                      >
                        {' '}No Task Alloted
                      </p>
                      <a lassName="Addtask" href=" ">
                        Add
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> */}


</div>
    
))
}

</div>    
  
</div>
<div className='free-space'></div>
        <Footer />
      </div>
    )
  }
}

export default weeklyreport