import React, { Component } from 'react';
import Header from './header';
import refresh from '../images/refresh.png';
import Footer from './footer';
import axios from 'axios';
import Delete from '../images/new_del_ic_1.png';
import ReactTable from "react-table-6";
import Swal from 'sweetalert2';
import Loader from '../images/loader2.gif';
import showmore from '../images/show_more_btn.png';
import Edit from '../images/new_edt_ic_1.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import New from '../images/add.png';
import moment from 'moment';

export class backloglist extends Component {

  constructor() {
		super();
		this.state = {
            BackLogListDatas:[],
            UnassignedBackLogListDatas:[],
            AssignedBackLogListDatas:[],
            InProgressBackLogListDatas:[],
            CompletedBackLogListDatas:[],
            LoginDatas:  JSON.parse(localStorage.getItem("HelpDeskLoginDatas")),
            Auth:  JSON.parse(localStorage.getItem("auth")),
            LoaderShow: false,
            showtab1: true,
            showtab2: false,
            showtab3: false,
            showtab4: false,
            showModal1: false,
            showModal2: false,
            ProjectDatas:[],
	  };
	}

  componentDidMount(){
    const Auth =  JSON.parse(localStorage.getItem("auth"));
      if(!Auth){
       this.props.history.push('/login');
      }
      console.log('LoginDatas' , this.state.LoginDatas);
      this.setState({ LoaderShow: true });
      this.setState({UnassignedBackLogListDatas: []});
      this.setState({AssignedBackLogListDatas: []});
      this.setState({InProgressBackLogListDatas: []});
      this.setState({CompletedBackLogListDatas: []});
    var Unassigned = 'https://helpdeskservice.desss-portfolio.com/api/BackLogList/LoadGrid?CompanyID=' +this.state.LoginDatas.CompanyID;
    axios.get(Unassigned)
    .then(res => {
      var data1 = res.data.data;
      if(data1[0].errorMsg === "No Data Found"){
        this.setState({ LoaderShow: false });
        return;
      }else{
       data1.forEach((key, value) => {
         this.state.UnassignedBackLogListDatas.push({
          Active: key.Active,
          Analysis: key.Analysis,
          AssignedTo: key.AssignedTo,
          Attachment1: key.Attachment1,
          Attachment2: key.Attachment2,
          Billable: key.Billable,
          BoardID: key.BoardID,
          CreatedBy: key.CreatedBy,
          CreatedOn: moment(key.CreatedOn).format("MM/DD/YYYY"),
          EstStartDate: moment(key.EstStartDate).format("MM/DD/YYYY"),
          EstEndDate: key.EstEndDate === null ? '' : moment(key.EstEndDate).format("MM/DD/YYYY"),
          Description:  key.Description,
          ModuleID:  key.ModuleID,
          ModuleName:  key.ModuleName,
          PhaseDescription:  key.PhaseDescription,
          PhaseDescriptionName:  key.PhaseDescriptionName,
          Priority:  key.Priority,
          PrjTaskID:  key.PrjTaskID,
          ProjectID:  key.ProjectID,
          ProjectName:  key.ProjectName,
          ReportBy:  key.ReportBy,
          ReportByName:  key.ReportByName,
          Source:  key.Source,
          SprintID:  key.SprintID,
          SprintName:  key.SprintName,
          TaskCategory:  key.TaskCategory,
          TaskCategoryName:  key.TaskCategoryName,
          TaskID:  key.TaskID,
          TaskName:  key.TaskName,
          TaskStatusID:  key.TaskStatusID,
          TaskType:  key.TaskType,
          TaskTypeName:  key.TaskTypeName,
          TicketNo:  key.TicketNo,
          Tools:  key.Tools,
          });
         })
      }
    this.setState({ LoaderShow: false });
    console.log('UnassignedBackLogListDatas',  this.state.UnassignedBackLogListDatas);
    })
    .catch(error => {
    console.log(error);
    this.setState({ LoaderShow: false });
    })

    var Assigned = 'https://helpdeskservice.desss-portfolio.com/api/BackLogList/LoadGridAssigned?CompanyID=' +this.state.LoginDatas.CompanyID;
    axios.get(Assigned)
    .then(res => {
      var data2 = res.data.data;
      if(data2[0].errorMsg === "No Data Found"){
        // this.setState({ LoaderShow: false });
        return;
      }else{
       data2.forEach((key, value) => {
         this.state.AssignedBackLogListDatas.push({
          Active: key.Active,
          Analysis: key.Analysis,
          AssignedTo: key.AssignedTo,
          Attachment1: key.Attachment1,
          Attachment2: key.Attachment2,
          Billable: key.Billable,
          BoardID: key.BoardID,
          CreatedBy: key.CreatedBy,
          CreatedOn: moment(key.CreatedOn).format("MM/DD/YYYY"),
          EstStartDate: moment(key.EstStartDate).format("MM/DD/YYYY"),
          EstEndDate: key.EstEndDate === null ? '' : moment(key.EstEndDate).format("MM/DD/YYYY"),
          Description:  key.Description,
          ModuleID:  key.ModuleID,
          ModuleName:  key.ModuleName,
          PhaseDescription:  key.PhaseDescription,
          PhaseDescriptionName:  key.PhaseDescriptionName,
          Priority:  key.Priority,
          PrjTaskID:  key.PrjTaskID,
          ProjectID:  key.ProjectID,
          ProjectName:  key.ProjectName,
          ReportBy:  key.ReportBy,
          ReportByName:  key.ReportByName,
          Source:  key.Source,
          SprintID:  key.SprintID,
          SprintName:  key.SprintName,
          TaskCategory:  key.TaskCategory,
          TaskCategoryName:  key.TaskCategoryName,
          TaskID:  key.TaskID,
          TaskName:  key.TaskName,
          TaskStatusID:  key.TaskStatusID,
          TaskType:  key.TaskType,
          TaskTypeName:  key.TaskTypeName,
          TicketNo:  key.TicketNo,
          Tools:  key.Tools,
          });
         })
      }
    // this.setState({ LoaderShow: false });
    console.log('AssignedBackLogListDatas',  this.state.AssignedBackLogListDatas);
    })
    .catch(error => {
    console.log(error);
    // this.setState({ LoaderShow: false });
    })

    var InProgress = 'https://helpdeskservice.desss-portfolio.com/api/BackLogList/LoadGridInProgress?CompanyID=' +this.state.LoginDatas.CompanyID;
    axios.get(InProgress)
    .then(res => {
      var data3 = res.data.data;
      if(data3[0].errorMsg === "No Data Found"){
        // this.setState({ LoaderShow: false });
        return;
      }else{
       data3.forEach((key, value) => {
         this.state.InProgressBackLogListDatas.push({
          Active: key.Active,
          Analysis: key.Analysis,
          AssignedTo: key.AssignedTo,
          Attachment1: key.Attachment1,
          Attachment2: key.Attachment2,
          Billable: key.Billable,
          BoardID: key.BoardID,
          CreatedBy: key.CreatedBy,
          CreatedOn: moment(key.CreatedOn).format("MM/DD/YYYY"),
          EstStartDate: moment(key.EstStartDate).format("MM/DD/YYYY"),
          EstEndDate: key.EstEndDate === null ? '' : moment(key.EstEndDate).format("MM/DD/YYYY"),
          Description:  key.Description,
          ModuleID:  key.ModuleID,
          ModuleName:  key.ModuleName,
          PhaseDescription:  key.PhaseDescription,
          PhaseDescriptionName:  key.PhaseDescriptionName,
          Priority:  key.Priority,
          PrjTaskID:  key.PrjTaskID,
          ProjectID:  key.ProjectID,
          ProjectName:  key.ProjectName,
          ReportBy:  key.ReportBy,
          ReportByName:  key.ReportByName,
          Source:  key.Source,
          SprintID:  key.SprintID,
          SprintName:  key.SprintName,
          TaskCategory:  key.TaskCategory,
          TaskCategoryName:  key.TaskCategoryName,
          TaskID:  key.TaskID,
          TaskName:  key.TaskName,
          TaskStatusID:  key.TaskStatusID,
          TaskType:  key.TaskType,
          TaskTypeName:  key.TaskTypeName,
          TicketNo:  key.TicketNo,
          Tools:  key.Tools,
          });
         })
      }
    // this.setState({ LoaderShow: false });
    console.log('InProgressBackLogListDatas',  this.state.InProgressBackLogListDatas);
    })
    .catch(error => {
    console.log(error);
    // this.setState({ LoaderShow: false });
    })

    var Completed = 'https://helpdeskservice.desss-portfolio.com/api/BackLogList/LoadGridCompleted?CompanyID=' +this.state.LoginDatas.CompanyID;
    axios.get(Completed)
    .then(res => {
      var data4 = res.data.data;
      if(data4[0].errorMsg === "No Data Found"){
        // this.setState({ LoaderShow: false });
        return;
      }else{
       data4.forEach((key, value) => {
         this.state.CompletedBackLogListDatas.push({
          Active: key.Active,
          Analysis: key.Analysis,
          AssignedTo: key.AssignedTo,
          Attachment1: key.Attachment1,
          Attachment2: key.Attachment2,
          Billable: key.Billable,
          BoardID: key.BoardID,
          CreatedBy: key.CreatedBy,
          CreatedOn: moment(key.CreatedOn).format("MM/DD/YYYY"),
          EstStartDate: moment(key.EstStartDate).format("MM/DD/YYYY"),
          EstEndDate: key.EstEndDate === null ? '' : moment(key.EstEndDate).format("MM/DD/YYYY"),
          Description:  key.Description,
          ModuleID:  key.ModuleID,
          ModuleName:  key.ModuleName,
          PhaseDescription:  key.PhaseDescription,
          PhaseDescriptionName:  key.PhaseDescriptionName,
          Priority:  key.Priority,
          PrjTaskID:  key.PrjTaskID,
          ProjectID:  key.ProjectID,
          ProjectName:  key.ProjectName,
          ReportBy:  key.ReportBy,
          ReportByName:  key.ReportByName,
          Source:  key.Source,
          SprintID:  key.SprintID,
          SprintName:  key.SprintName,
          TaskCategory:  key.TaskCategory,
          TaskCategoryName:  key.TaskCategoryName,
          TaskID:  key.TaskID,
          TaskName:  key.TaskName,
          TaskStatusID:  key.TaskStatusID,
          TaskType:  key.TaskType,
          TaskTypeName:  key.TaskTypeName,
          TicketNo:  key.TicketNo,
          Tools:  key.Tools,
          });
         })
      }
    this.setState({ LoaderShow: false });
    console.log('CompletedBackLogListDatas',  this.state.CompletedBackLogListDatas);
    })
    .catch(error => {
    console.log(error);
    // this.setState({ LoaderShow: false });
    })

  }


  
  
  handletab1 = () => {
    this.setState({ showtab1: true });
    this.setState({ showtab2: false });
    this.setState({ showtab3: false });
    this.setState({ showtab4: false });

  const tab1 = document.getElementById('first-tab');
  const tab2 = document.getElementById('second-tab');
  const tab3 = document.getElementById('third-tab');
  const tab4 = document.getElementById('fourth-tab');

      tab1.classList.add('active');
      tab2.classList.remove('active');
      tab3.classList.remove('active');
      tab4.classList.remove('active');

      // this.componentDidMount();
  
  };
  handletab2 = () => {
    this.setState({ showtab1: false });
    this.setState({ showtab2: true });
    this.setState({ showtab3: false });
    this.setState({ showtab4: false });
   
  const tab1 = document.getElementById('first-tab');
  const tab2 = document.getElementById('second-tab');
  const tab3 = document.getElementById('third-tab');
  const tab4 = document.getElementById('fourth-tab');
      tab1.classList.remove('active');
      tab2.classList.add('active');
      tab3.classList.remove('active');
      tab4.classList.remove('active');

      // this.componentDidMount();
  
  };

  handletab3 = () => {
    this.setState({ showtab1: false });
    this.setState({ showtab2: false });
    this.setState({ showtab3: true });
    this.setState({ showtab4: false });
  const tab1 = document.getElementById('first-tab');
  const tab2 = document.getElementById('second-tab');
  const tab3 = document.getElementById('third-tab');
  const tab4 = document.getElementById('fourth-tab');
      tab1.classList.remove('active');
      tab2.classList.remove('active');
      tab3.classList.add('active');
      tab4.classList.remove('active');
      // this.componentDidMount();
  
  };
  handletab4 = () => {
    this.setState({ showtab1: false });
    this.setState({ showtab2: false });
    this.setState({ showtab3: false });
    this.setState({ showtab4: true });

  const tab1 = document.getElementById('first-tab');
  const tab2 = document.getElementById('second-tab');
  const tab3 = document.getElementById('third-tab');
  const tab4 = document.getElementById('fourth-tab');

      tab1.classList.remove('active');
      tab2.classList.remove('active');
      tab3.classList.remove('active');
      tab4.classList.add('active');
      // this.componentDidMount();
  
  };

  handleCloseModal1 = () => {
    this.setState({ showModal1: false });
  };

  AddBacklogMethod = () => {
    this.props.history.push('/backlogNew');
  };

  EditBacklogMethod = (eve, data) => {
    var ProjectDetails = 'https://helpdeskservice.desss-portfolio.com/api/AllotedTaskList/Getboarddeatilsbyprjid?PrjTaskID=' + data.PrjTaskID;
    axios.get(ProjectDetails)
    .then(ProjectDetailsData => {
      localStorage.setItem("EditBackLogsDatas", JSON.stringify(ProjectDetailsData.data.data[0]));
      console.log('EditBackLogsDatas', JSON.stringify(ProjectDetailsData.data.data[0]));
      this.props.history.push('/backlogEdit');
    })
    .catch(error => {
    console.log(error);
    this.props.history.push('/backlogEdit');
    })
  };

  EstimateAndAllot = (eve, data) => {
    console.log('EstimateAndAllotBackLogsDatas', data);
    localStorage.setItem("EstimateAndAllotBackLogsDatas", JSON.stringify(data));
    this.props.history.push('/Estimate_and_Allot_BackLogs');
  };

  handleCloseModal2 = () => {
    this.setState({ showModal2: false });
  };

  handleOpenModal2 = () => {
    this.setState({ showModal2: true });
  };

  
filterCaseInsensitive = (filter, row) => {
  const id = filter.pivotId || filter.id;
  const content = row[id];
  if (typeof content !== 'undefined') {
      // filter by text in the table or if it's a object, filter by key
      if (typeof content === 'object' && content !== null && content.key) {
          return String(content.key).toLowerCase().includes(filter.value.toLowerCase());
      } else {
          return String(content).toLowerCase().includes(filter.value.toLowerCase());
      }
  }

  return true;
}

Editproject(res, datas){
    console.log('Edit Datas', datas);
    var ProjectDetails = 'http://helpdeskservice.desss-portfolio.com/api/QuickEdit/LoadAll?ProjectID=' + datas.ProjectID;
    axios.post(ProjectDetails)
    .then(ProjectDetailsData => {
      localStorage.setItem("EditprojectDatas", JSON.stringify(ProjectDetailsData.data.data[0]));
      console.log('ProjectDetailsData',  ProjectDetailsData.data.data[0]);
      this.props.history.push('/projectdetails');
    })
    .catch(error => {
    console.log(error);
    this.props.history.push('/projectdetails');
    })
}
Deletebacklog(res, datas){
  Swal.fire({
    title: "Are you sure?",
    text: "",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Yes, delete it!"
  }).then((result) => {
    

    if (result.isConfirmed) {

      console.log('DeleteTicket 10' , datas);
      var Delsubmodulegrid = 'https://helpdeskservice.desss-portfolio.com/api/DeleteSubModule/Grid_RowDelete?PrjTaskID='+datas.PrjTaskID;
  axios.post(Delsubmodulegrid)
  .then(res3 => {
    Swal.fire({
      icon: "success",
      title: "Backlog has been deleted",
      showConfirmButton: false,
      timer: 1500
     });

     this.componentDidMount();
    console.log('Delete module',  res3);
  })
  .catch(error => {
  console.log(error);
  })
      
      
    }

  });

}
  render() {

    const columns1 = [
      {
        Header: 'Project Name',
        accessor: 'ProjectName',
        width: "100%",
        style:{
          textAlign: "center"
        },
        Cell: props => {
          return (
              <button className='edit-btn animi' style={{width:"auto"}} onClick={ () => {this.Editproject(this, props.original);}}>{props.original.ProjectName}</button>
          )
      }
      },
         {
          Header: 'Module Name',
          accessor: 'ModuleName',
          style:{
              textAlign: "center"
          },
          width: "100%",
         },
         {
          Header: 'Backlog/Task',
          accessor: 'TaskName',
          style:{
              textAlign: "center"
          },
          width: "100%",
         },
         {
          Header: 'Issue Type',
          accessor: 'PhaseDescriptionName',
          style:{
              textAlign: "center"
          },
          width: "100%",
         },
         {
          Header: 'Start Date',
          accessor: 'EstStartDate',
          style:{
              textAlign: "center"
          },
          width: "100%",
         },
         {
          Header: 'End Date',
          accessor: 'EstEndDate',
          style:{
              textAlign: "center"
          },
          width: "100%",
         },
         {
          Header: 'Sprint',
          accessor: 'SprintName',
          style:{
              textAlign: "center"
          },
          width: "100%",
         },
      {
       Header: 'Task Status',
       accessor: 'CardviewStatus',
       style:{
           textAlign: "center"
       },
       width: "100%",
      },
      {
        Header: 'Source',
        accessor: 'Source',
        style:{
          textAlign: "center"
      },
      width: "100%",
      },
      {
        Header: 'Priority',
        accessor: 'Priority',
        style:{
          textAlign: "center"
      },
      width: "100%",
      },
      {
        Header: 'CreatedBy',
        accessor: 'CreatedBy',
        style:{
          textAlign: "center"
      },
      width: "100%",
      },
      {
        Header: 'Active',
        accessor: 'Active',
        style:{
            textAlign: "center",
            width:'100px'
        },
        width: "150px",
        Cell: props => {
          return(
            <input type='checkbox'  name="GridChk1" checked={props.original.Active === true}  style={{width:"auto"}} />
        )
      }
      },
      {
        Header: 'Billable',
        accessor: 'Billable',
        style:{
            textAlign: "center",
            width:'100px'
        },
        width: "150px",
        Cell: props => {
          return(
            <input type='checkbox'  name="GridChk1" checked={props.original.Billable === true}  style={{width:"auto"}} />
        )
      }
      },
      {
        Header: 'Estimate and Allot',
        // accessor: 'ProjectName',
        width: "100%",
        style:{
          textAlign: "center"
        },
        Cell: props => {
          return (
              <button className='edit-btn animi' style={{width:"auto"}} onClick={ () => {this.EstimateAndAllot(this, props.original);}}>{'Estimate and Allot'}</button>
          )
      }
      },
      {
        Header: 'Edit',
        accessor: 'CurrentTask',
        style:{
          textAlign: "center"
      },
      // width 40 added sp 09-09-2024
        width: 40,
        minWidth: 100,
        maxWidth: 40,
      Cell: props => {
        return (
            <button className='del-btn'style={{width:"auto"}}   onClick={() => {this.EditBacklogMethod(this, props.original);}}> 
              <img title="Delete" className="dxgvCommandColumnItem_Office2003Blue dxgv__cci" src={Edit} alt="Delete" style={{color: '#06638F', height: '17px', borderWidth: '0px',cursor: 'pointer'}} />
            </button>
        )
    },
      },
      {
        Header: 'Delete',
        accessor: 'CurrentTask',
        style:{
          textAlign: "center"
      },
      // sp width 40 added 09-09-2024
        width: 50,
        minWidth: 100,
        maxWidth: 50,
      Cell: props => {
        return (
            <button className='del-btn'style={{width:"auto"}} 
            onClick={() => {this.Deletebacklog(this, props.original);
          }}><img title="Delete" className="dxgvCommandColumnItem_Office2003Blue dxgv__cci" src={Delete} alt="Delete" style={{color: '#06638F', height: '17px', borderWidth: '0px',cursor: 'pointer'}} /></button>
        )
    },
      }
    ]
    return (
      <div>

         {this.state.LoaderShow === true ? (
          <div className='loader-img'>
            <img   src={Loader} height="50" width="50" alt="Helpdesk" />
          </div>
        ) : (
          ''
        )}

        <Header />
        <div className="centerBG" style={{width: "90%",margin: "0px auto", maxWidth: "1695px"}}>
        <div id="second-panel" className="panel animated slideInRight">

  <table cellSpacing={0} cellPadding={0} border={0} style={{height: '100%', width: '100%', borderCollapse: 'collapse'}}>
  <tbody>
    <tr style={{height: '100%'}}>
      <td style={{color: '#333333', fontSize: '0.8em'}}>
        <table className="table-content" cellPadding="0px" cellSpacing="0px" width="100%">
          <tbody><tr>
              <td className="sub-head" style={{backgroundColor: "#ffdead00",float: "left" , padding:"7px"}}>BackLog List</td>
              <Link className='edit-btn-submodule'  onClick={ () => {this.AddBacklogMethod();}} >
               {/* sp 09-09-2024 */}
              <img src={New} alt="add new" style={{color: '#06638F', height: '14px', borderWidth: '0px'}} />
              
              <span style={{fontSize:'14px',padding:'10px'}}>New BackLog</span>
               {/* sp 09-09-2024 */}
            </Link>
            </tr>
            <tr>
            </tr></tbody></table><table className="table-backloglist" cellPadding="0px" cellSpacing="0px" width="100%">
          <tbody>
            <tr>
              {/* <td>
                <ReactTable
                  columns={columns1}
                  data={this.state.BackLogListDatas}
                  noDataText={"No Data..."}
                  selectableRows
                  filterable
                  defaultPageSize={25}
                  defaultFilterMethod={this.filterCaseInsensitive}
                  >
                </ReactTable>
              </td> */}
              <td>
              <div className="radio-tabs">
              <div className="tabs">
              <label htmlFor="radio1" id="first-tab" className="tab active">
                  <div className="tab-label" onClick={ () => {this.handletab1();}}>UnAssigned Backlog List</div>
                </label>
                <label htmlFor="radio2" id="second-tab" className="tab">
                  <div className="tab-label" onClick={ () => {this.handletab2();}}>Assigned Backlog List</div>
                </label>
                <label htmlFor="radio3" id="third-tab" className="tab">
                  <div className="tab-label" onClick={ () => {this.handletab3();}}>InProgress Backlog List</div>
                </label>
                <label htmlFor="radio4" id="fourth-tab" className="tab">
                  <div className="tab-label" onClick={ () => {this.handletab4();}}>Completed Backlog List</div>
                </label>
              </div>
              <div className="tabs-container">
                  {this.state.showtab1 && (
                    <div id="first-panel" className="panel active animated slideInRight">
                      <table>
                        <tbody>
                          <tr>
                            {/* sp 09-09-2024 */}
                            <td className="heading_bg" style={{display:'ruby-text',padding:'5px 10px',fontSize:'25px',fontWeight:'600',paddingBottom:'0px',paddingTop:'20px'}}>
                            UnAssigned Backlog List
                            </td>
                          </tr>
                          <tr className='backloglist-grid' style={{width: '100%'}}>
                            <td>
                            <ReactTable
                              columns={columns1}
                              data={this.state.UnassignedBackLogListDatas}
                              noDataText={"No Data..."}
                              filterable
                              defaultPageSize={25}
                              defaultFilterMethod={this.filterCaseInsensitive}
                              >
                            </ReactTable>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    )} 
                    {this.state.showtab2 && (
                     <div id="third-panel" className="panel animated slideInRight">
                    <table>
                      <tbody>
                        <tr>
                          <td className="heading_bg" style={{display:'ruby-text',padding:'5px 10px',fontSize:'25px',fontWeight:'600',paddingBottom:'0px',paddingTop:'20px'}}>
                          Assigned Backlog List
                          </td>
                        </tr>
                        <tr className='backloglist-grid'>
                          <td>
                          <ReactTable
                            columns={columns1}
                            data={this.state.AssignedBackLogListDatas}
                            noDataText={"No Data..."}
                            filterable
                            defaultPageSize={10}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            >
                          </ReactTable>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                    )} 
                   {this.state.showtab3 && (
                    <div id="third-panel" className="panel animated slideInRight">
                    <table>
                    <tbody>
                        <tr>
                          <td className="heading_bg" style={{display:'ruby-text',padding:'5px 10px',fontSize:'25px',fontWeight:'600',paddingBottom:'0px',paddingTop:'20px'}}>
                          InProgress Backlog List
                          </td>
                        </tr>
                        <tr className='backloglist-grid'>
                          <td>
                          <ReactTable
                            columns={columns1}
                            data={this.state.InProgressBackLogListDatas}
                            noDataText={"No Data..."}
                            filterable
                            defaultPageSize={25}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            >
                          </ReactTable>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                    )} 
                    {this.state.showtab4 && (
                      <div id="fourth-panel" className="panel animated slideInRight">
                      <div className='four-tab'>
                      <table>
                        <tbody>
                          <tr>
                            <td className="heading_bg" style={{display:'ruby-text',padding:'5px 10px',fontSize:'25px',fontWeight:'600',paddingBottom:'0px',paddingTop:'20px'}}>
                            Completed Backlog List
                            </td>
                          </tr>
                          <tr className='backloglist-grid'>
                            <td>
                            <ReactTable
                              columns={columns1}
                              data={this.state.CompletedBackLogListDatas}
                              noDataText={"No Data..."}
                              filterable
                              defaultPageSize={25}
                              defaultFilterMethod={this.filterCaseInsensitive}
                              >
                            </ReactTable>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      </div>
                      </div>
                    )} 

  </div>
              {/* <div className="active-tabs">
                <input type="radio" name="active_tabs" id="btn-1" className="btn-1" defaultChecked />
                <label htmlFor="btn-1" className="btn">
                UnAssigned Backlog List
                </label>
                <input type="radio" name="active_tabs" id="btn-2" className="btn-2" />
                <label htmlFor="btn-2" className="btn">
                Assigned Backlog List
                </label>
               <input type="radio" name="active_tabs" id="btn-3" className="btn-3" />
                <label htmlFor="btn-3" className="btn">
               InProgress Backlog List
                </label>
                <input type="radio" name="active_tabs" id="btn-4" className="btn-4" />
                <label htmlFor="btn-4" className="btn">
                Completed Backlog List
                </label>
                
                <div className="tabs-container">
                 
                  <div className="tab-2">
                    <table>
                      <tbody>
                        <tr>
                          <td className="heading_bg">
                          UnAssigned Backlog List
                          </td>
                        </tr>
                        <tr className='backloglist-grid' style={{width: '100%'}}>
                          <td>
                          <ReactTable
                            columns={columns1}
                            data={this.state.UnassignedBackLogListDatas}
                            noDataText={"No Data..."}
                            filterable
                            defaultPageSize={25}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            >
                          </ReactTable>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  </div>  
                  <div className="tab-2">
                    <table>
                      <tbody>
                        <tr>
                          <td className="heading_bg">
                          Assigned Backlog List
                          </td>
                        </tr>
                        <tr className='backloglist-grid'>
                          <td>
                          <ReactTable
                            columns={columns1}
                            data={this.state.AssignedBackLogListDatas}
                            noDataText={"No Data..."}
                            filterable
                            defaultPageSize={10}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            >
                          </ReactTable>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="tab-3">
                    <table>
                    <tbody>
                        <tr>
                          <td className="heading_bg">
                          InProgress Backlog List
                          </td>
                        </tr>
                        <tr className='backloglist-grid'>
                          <td>
                          <ReactTable
                            columns={columns1}
                            data={this.state.InProgressBackLogListDatas}
                            noDataText={"No Data..."}
                            filterable
                            defaultPageSize={25}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            >
                          </ReactTable>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="tab-4">
                    <div className='four-tab'>
                    <table>
                      <tbody>
                        <tr>
                          <td className="heading_bg">
                          Completed Backlog List
                          </td>
                        </tr>
                        <tr className='backloglist-grid'>
                          <td>
                          <ReactTable
                            columns={columns1}
                            data={this.state.CompletedBackLogListDatas}
                            noDataText={"No Data..."}
                            filterable
                            defaultPageSize={25}
                            defaultFilterMethod={this.filterCaseInsensitive}
                            >
                          </ReactTable>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    </div>
                  </div>
                </div> */}
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </td>
    </tr>
  </tbody>
</table>


<label htmlFor="radio1" id="first-tab" className="tab" style={{width:"100%"}}>
  <button className="tab-label" style={{backgroundColor: "#4c4a48",color: "#fff",border: "none",padding: "7px 15px",marginTop: "10px",borderRadius: "6px",marginRight: "10px"}}  onClick={ () => {this.handletab1();}}>Previous</button>
</label>

</div>



{/* Popup  */}

{/* new */}

{this.state.showModal1 && (
        <div className="popup-create">
          
        <div className='popup-body'>
      <div id="Grid_DXPEForm_PW-1" className="dxpcLite_Office2003Blue dxpclW dxpc-mainDiv dxpc-shadow" style={{fontFamily: 'Calibri',padding: '20px', fontSize: 15, width: 498, cursor: 'default', visibility: 'visible',overflow:"auto",minHeight: "300px", maxHeight: "500px"}}>
<div className="popup-header" style={{fontFamily: 'Calibri', fontSize: 15,width: "100%" , display: "inline-block"}} id="Grid_DXPEForm_PWH-1">
  <div className="dxpc-headerContent" style={{float:'left'}}>
    <span className="dxpc-headerText" id="Grid_DXPEForm_PWH-1T">Add New Submodule</span>
  </div>
  <button className='popup-closebtn' onClick={this.handleCloseModal1} style={{float:'right'}}>X</button>
  <b className="dx-clear" />
</div><div className="dxpc-contentWrapper" >
  <div className="dxpc-content" style={{fontFamily: 'Calibri', fontSize: 15, padding: 0, display: 'block'}} id="Grid_DXPEForm_PWC-1">
    <div className="dxgvPopupEditForm_Office2003Blue" style={{overflowX: 'hidden'}}>
    <table>
        <tbody>
          <tr id="Grid_DXDataRow0" className="dxgvDataRow_Office2003Blue" style={{fontFamily: 'Calibri', fontSize: 15}}>
            <td></td>
            <td>
              <ReactTable
                columns={columns1}
                data={this.state.ProjectDatas}
                noDataText={"No Data..."}
                filterable
                defaultPageSize={25}
                defaultFilterMethod={this.filterCaseInsensitive}
                >
              </ReactTable>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


      </div>

        </div>            

          
        </div>
      )}

{/* new end */}

{/* edit */}

{this.state.showModal2 && (
        <div className="popup-create">
          
        <div className='popup-body'>
      <div id="Grid_DXPEForm_PW-1" className="dxpcLite_Office2003Blue dxpclW dxpc-mainDiv dxpc-shadow" style={{fontFamily: 'Calibri',padding: '20px', fontSize: 15, width: 498, cursor: 'default', visibility: 'visible',overflow:"auto",minHeight: "300px", maxHeight: "500px"}}>
<div className="popup-header" style={{fontFamily: 'Calibri', fontSize: 15,width: "100%" , display: "inline-block"}} id="Grid_DXPEForm_PWH-1">
  <div className="dxpc-headerContent" style={{float:'left'}}>
    <span className="dxpc-headerText" id="Grid_DXPEForm_PWH-1T">Add New Submodule</span>
  </div>
  <button className='popup-closebtn' onClick={this.handleCloseModal2} style={{float:'right'}}>X</button>
  <b className="dx-clear" />
</div><div className="dxpc-contentWrapper" >
  <div className="dxpc-content" style={{fontFamily: 'Calibri', fontSize: 15, padding: 0, display: 'block'}} id="Grid_DXPEForm_PWC-1">
    <div className="dxgvPopupEditForm_Office2003Blue" style={{overflowX: 'hidden'}}>
    <table>
        <tbody>
          <tr id="Grid_DXDataRow0" className="dxgvDataRow_Office2003Blue" style={{fontFamily: 'Calibri', fontSize: 15}}>
            <td></td>
            <td>
              <ReactTable
                columns={columns1}
                data={this.state.ProjectDatas}
                noDataText={"No Data..."}
                filterable
                defaultPageSize={25}
                defaultFilterMethod={this.filterCaseInsensitive}
                >
              </ReactTable>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>


      </div>

        </div>            

          
        </div>
      )}

{/* edit end */}



    </div>

        <div className='free-space'></div>
        <Footer />
      </div>
    )
  }
}

export default backloglist